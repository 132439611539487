
import Vue from "vue";

import { mapGetters, mapMutations, mapActions } from "vuex"; // mapState
import { removeLocalStoreCache } from "@/utilsCache";

import HarvestForm from "./forms/HarvestForm.vue";
import Harvest from "@/models/harvest";

interface ITurnScenary {
  current?: Harvest;
  future?: Harvest;
}

export default Vue.extend({
  name: "HarvestTurn",
  data() {
    return {
      // Indica se selecionou virar safra
      turn: false,
      // Indica se selecionou reverter a safra
      revert: false,
      // obj representa o cenário de mudança
      scenery: {} as ITurnScenary,
      dialog: false,
    };
  },
  components: {
    HarvestForm,
  },
  methods: {
    ...mapMutations(["updateAdminResources"]),
    ...mapActions({
      myOwnerships: "MY_OWNERSHIPS",
      makeHarvestTurn: "MAKE_HARVEST_TURN",
    }),
    turnHarvest(): void {
      this.scenery = {
        current: this.future,
        future: this.harvestToFuture,
      };
      this.turn = true;
    },
    cancel(): void {
      this.$router.push({ path: "/admin/sysmanager" });
    },
    async selectNewHarvest(newItem: Harvest): Promise<void> {
      await this.updateAdminResources({ array: "allHarvests", item: newItem });
      if (this.scenery) this.scenery.future = newItem;
    },
    async confirm(): Promise<void> {
      try {
        await this.makeHarvestTurn(this.scenery);
        removeLocalStoreCache();
        this.$router.go(0);
        this.$alertSuccess("Safra virada com sucesso");
      } catch (error) {
        this.$alertError("Ocorreu um problema ao virar a safra");
      }
    },
  },
  computed: {
    ...mapGetters({
      allHarvests: "getAllHarvests",
      allUnits: "getAllUnits",
    }),
    current(): Harvest | undefined {
      if (this.allHarvests)
        return this.allHarvests.find((h: Harvest) => h.is_current);
      return undefined;
    },
    future(): Harvest | undefined {
      if (this.allHarvests)
        return this.allHarvests.find((h: Harvest) => h.is_future);
      return undefined;
    },
    harvestToFuture(): Harvest | undefined {
      if (this.allHarvests)
        return this.allHarvests.find((h: Harvest) => {
          if (
            this.future &&
            (h.created_at && this.future.created_at) &&
            this.$luxon(h.created_at) > this.$luxon(this.future.created_at)
          ) {
            return h;
          }
        });
      return undefined;
    },
    // Apenas habilita o btn de confirmar
    // quando a safra atual e futura selecionadas
    readyToGo(): boolean {
      if (this.scenery) {
        return this.scenery.current && this.scenery.future ? true : false;
      }
      return false;
    },
  },
});
